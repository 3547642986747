// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UnitedArabEmiratesForm-module-wrapper-9zhcJ{display:flex;flex-flow:column wrap;gap:0 20px;gap:0 var(--spacing-sm, 20px)}@media(min-width: 960px){.UnitedArabEmiratesForm-module-wrapper-9zhcJ{display:grid;grid-template-columns:repeat(10, 1fr);grid-template-rows:repeat(3, 1fr)}}.UnitedArabEmiratesForm-module-neighborhood-sqX9D{grid-row:1/2;grid-column:1/6}.UnitedArabEmiratesForm-module-streetName-Y60eu{grid-row:1/2;grid-column:6/11}.UnitedArabEmiratesForm-module-premise-A13Qh{grid-row:2/3;grid-column:1/6}.UnitedArabEmiratesForm-module-streetNumber-vQcT3{grid-row:2/3;grid-column:6/11}.UnitedArabEmiratesForm-module-city-FgS2u{grid-row:3/4;grid-column:1/4}@media(min-width: 1280px){.UnitedArabEmiratesForm-module-city-FgS2u{grid-column:1/3}}.UnitedArabEmiratesForm-module-landmark-\\+AOQb{grid-row:3/4;grid-column:4/11}@media(min-width: 1280px){.UnitedArabEmiratesForm-module-landmark-\\+AOQb{grid-column:3/11}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "UnitedArabEmiratesForm-module-wrapper-9zhcJ",
	"neighborhood": "UnitedArabEmiratesForm-module-neighborhood-sqX9D",
	"streetName": "UnitedArabEmiratesForm-module-streetName-Y60eu",
	"premise": "UnitedArabEmiratesForm-module-premise-A13Qh",
	"streetNumber": "UnitedArabEmiratesForm-module-streetNumber-vQcT3",
	"city": "UnitedArabEmiratesForm-module-city-FgS2u",
	"landmark": "UnitedArabEmiratesForm-module-landmark-+AOQb"
};
export default ___CSS_LOADER_EXPORT___;
