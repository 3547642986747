// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserActions-module-userDialog-gO85Z{position:absolute;bottom:0;inset-inline:auto 0;width:320px;width:var(--width-2xs, 320px);min-width:320px;min-width:var(--width-2xs, 320px);transform:translateY(100%);padding:20px;padding:var(--spacing-sm, 20px);background:var(--colors-background-bg-octonary);display:flex;flex-direction:column;justify-content:center;border-radius:8px;border-radius:var(--radius-md, 8px);box-shadow:0px 10px 16px 0px rgba(0,0,0,.06)}.UserActions-module-userDailogList-fN68k{display:flex;flex-direction:column}.UserActions-module-userDailogListItem-9S47R{padding-block:16px;padding-block:var(--spacing-xs, 16px);padding-inline:10px 64px;padding-inline:var(--spacing-3xs, 10px) var(--spacing-3xl, 64px);gap:4px;gap:var(--spacing-5xs, 4px);cursor:pointer}.UserActions-module-userDailogListItem-9S47R a{text-decoration:none;color:var(--colors-text-text-secondary);font-size:14px;font-size:var(--size-text-small, 14px);line-height:var(--line-height-text-small);letter-spacing:var(--letter-spacing-none)}.UserActions-module-dialogTitle-VOitn{color:#5c5c5c;color:var(--colors-text-text-tertiary, #5c5c5c);font-size:16px;font-size:var(--size-text-md, 16px);text-transform:uppercase;line-height:22px;line-height:var(--line-height-text-md, 22px);text-align:center;width:100%;font-weight:500;font-weight:var(--fonts-weight-medium, 500)}.UserActions-module-dialogClose-SuR6H{position:absolute;top:20px;inset-inline-end:20px;cursor:pointer}.UserActions-module-dialogClose-SuR6H svg{fill:var(--colors-foreground-fg-primary)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userDialog": "UserActions-module-userDialog-gO85Z",
	"userDailogList": "UserActions-module-userDailogList-fN68k",
	"userDailogListItem": "UserActions-module-userDailogListItem-9S47R",
	"dialogTitle": "UserActions-module-dialogTitle-VOitn",
	"dialogClose": "UserActions-module-dialogClose-SuR6H"
};
export default ___CSS_LOADER_EXPORT___;
