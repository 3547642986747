// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tools-module-tools-MIiS2{display:inline-flex;flex-direction:column;padding-block:0;padding-inline:5px}.Tools-module-list-56QB\\+{display:flex;flex-direction:row}.Tools-module-item-YpbLf{padding:12px;font-size:18px;height:100%;border-radius:8px;border-radius:var(--radius-md, 8px)}.Tools-module-item-YpbLf:hover{background:#f5f5f5;background:var(--colors-background-bg-septenary, #f5f5f5)}.Tools-module-item-YpbLf:active{background:var(--colors-background-bg-brand_alpha)}.Tools-module-item-YpbLf svg path{fill:var(--colors-foreground-fg-primary)}.Tools-module-itemOpen-fsIKR{background:var(--colors-background-bg-brand_alpha)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tools": "Tools-module-tools-MIiS2",
	"list": "Tools-module-list-56QB+",
	"item": "Tools-module-item-YpbLf",
	"itemOpen": "Tools-module-itemOpen-fsIKR"
};
export default ___CSS_LOADER_EXPORT___;
