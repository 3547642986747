// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextArea-module-textarea-IkIZm{resize:none;min-height:150px;width:100%;border-radius:8px;padding:15px;font-family:inherit;font-size:inherit;font-weight:inherit}.TextArea-module-textarea-IkIZm:read-only{background-color:#e2e2e2;border-color:#e2e2e2;text-overflow:ellipsis}.TextArea-module-textarea-IkIZm:not(:disabled):focus,.TextArea-module-textarea-IkIZm:not(:disabled):active{border:2px solid #4681ff;border-radius:8px}.TextArea-module-counter-oPI5s{color:#5c615e;display:flex;justify-content:flex-end}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "TextArea-module-textarea-IkIZm",
	"counter": "TextArea-module-counter-oPI5s"
};
export default ___CSS_LOADER_EXPORT___;
