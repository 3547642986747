// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes HealthBanner-module-flickerAnimation-awkx\\+{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes HealthBanner-module-fadeInAnimation-7I\\+Nk{0%{opacity:0}100%{opacity:1}}@keyframes HealthBanner-module-fadeInFromTop-Nl9jd{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes HealthBanner-module-fadeOutToTop-HsNtV{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes HealthBanner-module-fadeInFromLeftAnimation-C3Ki5{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes HealthBanner-module-textClip-\\+MYEL{to{background-size:100% 100%}}@keyframes HealthBanner-module-scroll-GgHRL{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes HealthBanner-module-flickerAnimation-awkx\\+{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes HealthBanner-module-fadeInAnimation-7I\\+Nk{0%{opacity:0}100%{opacity:1}}@keyframes HealthBanner-module-fadeInFromTop-Nl9jd{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes HealthBanner-module-fadeOutToTop-HsNtV{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes HealthBanner-module-fadeInFromLeftAnimation-C3Ki5{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes HealthBanner-module-textClip-\\+MYEL{to{background-size:100% 100%}}@keyframes HealthBanner-module-scroll-GgHRL{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.HealthBanner-module-HealthBannerContainer-S7NHm{background-color:#131110;margin-top:70px;border-radius:8px;padding-block:15px;padding-inline:0;text-align:center}.HealthBanner-module-HealthBannerContent-QqL3R{color:#fff;width:80%;margin-block:0;margin-inline:auto;text-transform:uppercase;line-height:16px;font-size:12px;letter-spacing:3.75px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HealthBannerContainer": "HealthBanner-module-HealthBannerContainer-S7NHm",
	"HealthBannerContent": "HealthBanner-module-HealthBannerContent-QqL3R",
	"flickerAnimation": "HealthBanner-module-flickerAnimation-awkx+",
	"fadeInAnimation": "HealthBanner-module-fadeInAnimation-7I+Nk",
	"fadeInFromTop": "HealthBanner-module-fadeInFromTop-Nl9jd",
	"fadeOutToTop": "HealthBanner-module-fadeOutToTop-HsNtV",
	"fadeInFromLeftAnimation": "HealthBanner-module-fadeInFromLeftAnimation-C3Ki5",
	"textClip": "HealthBanner-module-textClip-+MYEL",
	"scroll": "HealthBanner-module-scroll-GgHRL"
};
export default ___CSS_LOADER_EXPORT___;
