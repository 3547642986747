// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width: 768px){.SearchForm-module-searchFormWrapper-F7WgX{display:flex;justify-content:space-between;align-items:flex-end;gap:20px}}.SearchForm-module-searchFormWrapper-F7WgX .SearchForm-module-searchSubmit-fcx6y{width:100%;text-wrap:nowrap}@media(min-width: 768px){.SearchForm-module-searchFormWrapper-F7WgX .SearchForm-module-searchSubmit-fcx6y{width:auto}}.SearchForm-module-searchInputWrapper-b5uFG{position:relative;width:100%;margin-bottom:20px}@media(min-width: 768px){.SearchForm-module-searchInputWrapper-b5uFG{margin-bottom:0}}.SearchForm-module-searchInputWrapper-b5uFG .SearchForm-module-searchField-Z\\+Uad{padding-block:0;padding-inline:30px;border:1px solid #373a36}.SearchForm-module-searchInputWrapper-b5uFG .SearchForm-module-searchField-Z\\+Uad:active,.SearchForm-module-searchInputWrapper-b5uFG .SearchForm-module-searchField-Z\\+Uad:focus{border:1px solid #373a36}.SearchForm-module-searchInputWrapper-b5uFG .SearchForm-module-searchIcon-OctA3{position:absolute;top:18px;inset-inline-start:0;padding:8px;background:none;color:#131110;cursor:pointer}.SearchForm-module-searchInputWrapper-b5uFG .SearchForm-module-closeIcon-aRLET{position:absolute;top:18px;inset-inline-end:0;padding-block:6px;padding-inline:11px;background:none;color:#131110;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchFormWrapper": "SearchForm-module-searchFormWrapper-F7WgX",
	"searchSubmit": "SearchForm-module-searchSubmit-fcx6y",
	"searchInputWrapper": "SearchForm-module-searchInputWrapper-b5uFG",
	"searchField": "SearchForm-module-searchField-Z+Uad",
	"searchIcon": "SearchForm-module-searchIcon-OctA3",
	"closeIcon": "SearchForm-module-closeIcon-aRLET"
};
export default ___CSS_LOADER_EXPORT___;
