// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBox-module-container-4mMih{width:100%;max-width:100%;padding-inline:18px}@media(min-width: 480px){.SearchBox-module-container-4mMih{padding-inline:10px}}@media(min-width: 768px){.SearchBox-module-container-4mMih{max-width:1280px;margin-inline:auto;padding-inline:12px}}.SearchBox-module-searchWrapper-ebmWI{padding:20px;border:1px solid #707472;border-radius:8px;background-color:#fbfbfb}@media(min-width: 768px){.SearchBox-module-searchWrapper-ebmWI{padding:25px}}.SearchBox-module-searchText-9HbWA{display:-webkit-box;-webkit-line-clamp:3;line-clamp:3;-webkit-box-orient:vertical;margin-bottom:10px;overflow:hidden;font-size:18px;line-height:24px;color:#3a3d3f}@media(min-width: 768px){.SearchBox-module-searchText-9HbWA{margin-bottom:5px;font-size:20px;line-height:38px}}.SearchBox-module-searchText-9HbWA.SearchBox-module-searchFaq-aBMAX{font-size:26px;line-height:38px;color:#2d2926}@media(min-width: 768px){.SearchBox-module-searchText-9HbWA.SearchBox-module-searchFaq-aBMAX{font-size:32px;line-height:44px}}.SearchBox-module-totalResults-2IfSs{margin-top:20px;font-weight:450;color:#3a3d3f}@media(min-width: 768px){.SearchBox-module-totalResults-2IfSs{margin-top:16px;font-size:14px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchBox-module-container-4mMih",
	"searchWrapper": "SearchBox-module-searchWrapper-ebmWI",
	"searchText": "SearchBox-module-searchText-9HbWA",
	"searchFaq": "SearchBox-module-searchFaq-aBMAX",
	"totalResults": "SearchBox-module-totalResults-2IfSs"
};
export default ___CSS_LOADER_EXPORT___;
