// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BreadcrumbItem-module-breadcrumbItem-n5cyO{margin-inline-end:5px;display:flex;align-items:center}.BreadcrumbItem-module-breadcrumbItem-n5cyO.BreadcrumbItem-module-hidden-VFIe3{display:none}.BreadcrumbItem-module-breadcrumbLink-duoqN{color:var(--colors-foreground-fg-primary_alpha_50);font-size:12px;line-height:2;font-weight:700;font-weight:var(--fonts-weight-bold, 700);text-decoration:none;cursor:pointer}.BreadcrumbItem-module-breadcrumbChevron-cSv7C{transform:rotate(180deg);margin-inline-end:5px;color:var(--colors-foreground-fg-primary_alpha_50)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbItem": "BreadcrumbItem-module-breadcrumbItem-n5cyO",
	"hidden": "BreadcrumbItem-module-hidden-VFIe3",
	"breadcrumbLink": "BreadcrumbItem-module-breadcrumbLink-duoqN",
	"breadcrumbChevron": "BreadcrumbItem-module-breadcrumbChevron-cSv7C"
};
export default ___CSS_LOADER_EXPORT___;
