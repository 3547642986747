// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes EmailInput-module-flickerAnimation-tAwQP{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes EmailInput-module-fadeInAnimation-a0sLM{0%{opacity:0}100%{opacity:1}}@keyframes EmailInput-module-fadeInFromTop-Zja67{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes EmailInput-module-fadeOutToTop-0VW0j{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes EmailInput-module-fadeInFromLeftAnimation-fhTcd{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes EmailInput-module-textClip-6MeAr{to{background-size:100% 100%}}@keyframes EmailInput-module-scroll-zxc\\+o{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes EmailInput-module-flickerAnimation-tAwQP{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes EmailInput-module-fadeInAnimation-a0sLM{0%{opacity:0}100%{opacity:1}}@keyframes EmailInput-module-fadeInFromTop-Zja67{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes EmailInput-module-fadeOutToTop-0VW0j{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes EmailInput-module-fadeInFromLeftAnimation-fhTcd{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes EmailInput-module-textClip-6MeAr{to{background-size:100% 100%}}@keyframes EmailInput-module-scroll-zxc\\+o{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.EmailInput-module-inputEmail-ZEjB4{padding-inline-start:55px;color:#373a36}.EmailInput-module-emailInputContainer-EgX0Z{position:relative}.EmailInput-module-emailInputContainer-EgX0Z path{fill:#373a36}.EmailInput-module-emailInputIcon-6QPmU{width:20px;height:auto;position:absolute;inset-inline-start:20px;top:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputEmail": "EmailInput-module-inputEmail-ZEjB4",
	"emailInputContainer": "EmailInput-module-emailInputContainer-EgX0Z",
	"emailInputIcon": "EmailInput-module-emailInputIcon-6QPmU",
	"flickerAnimation": "EmailInput-module-flickerAnimation-tAwQP",
	"fadeInAnimation": "EmailInput-module-fadeInAnimation-a0sLM",
	"fadeInFromTop": "EmailInput-module-fadeInFromTop-Zja67",
	"fadeOutToTop": "EmailInput-module-fadeOutToTop-0VW0j",
	"fadeInFromLeftAnimation": "EmailInput-module-fadeInFromLeftAnimation-fhTcd",
	"textClip": "EmailInput-module-textClip-6MeAr",
	"scroll": "EmailInput-module-scroll-zxc+o"
};
export default ___CSS_LOADER_EXPORT___;
